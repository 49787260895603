<template>
  <div class="user">
    <search-header :isSnShow="false"></search-header>
    <div class="container pt-3 pb-3 p-0">
      <div class="row p-0">
        <div class="col-2">
          <user-left :currentActive="8"></user-left>
        </div>
        <div class="col-10 f-14">
          <div class="row bg-white rounded ml-2">
            <div class="p-3 col-12">
              <b-tabs content-class="mt-3" lazy>
                <b-tab title="我的问题">
                  <v-question></v-question>
                </b-tab>
                <b-tab title="我的回答">
                  <v-answer></v-answer>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <map-footer :isMapShow="false"></map-footer>
  </div>
</template>

<script>
import vQuestion from './components/v-question.vue';
import vAnswer from './components/v-answer.vue';
export default {
  components: { vQuestion, vAnswer },
  data() {
    return {
    };
  },
  methods: {
  }
};
</script>

<style scoped>
@import url("~@/assets/css/user.css");
</style>
