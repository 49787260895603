<template>
  <div>
    <b-alert v-if="statistics" show>
    <span>提问数 {{statistics.askNumber}} , 问题解决率 {{ statistics.solvedNumber > 0 && statistics.answerNumber > 0 ? parseFloat((statistics.solvedNumber / statistics.answerNumber * 100).toFixed(2)) : 0}}% , 帮助人数 {{statistics.solvedNumber}}</span>
    </b-alert>
    <div class="w-100">
    <div class="card border-0">
        <div class="card-body p-0">
        <div class="table-responsive">
            <table class="table table-centered border mb-0">
            <thead class="table-light">
                <tr>
                <th class="w-65">问题</th>
                <th>提问时间</th>
                <th>状态</th>
                <th style="width: 80px">操作</th>
                </tr>
            </thead>
            <tbody v-if="list.total > 0">
                <tr v-for="m,i in list.records" :key="i">
                <td>
                    <router-link :to="'/ask/detail?id=' + m.id" target="_blank">
                    <h6>
                        {{m.title}}
                    </h6>
                    </router-link>

                    <div class="mt-2 text-gray-777">
                    <router-link v-if="getTagValue(m.classId,m.childClassId)"
                        class="aw-question-tags"
                        :to="'/ask?id=' + (m.childClassId > 0 ? m.childClassId : m.classId)" target="_blank">{{getTagValue(m.classId,m.childClassId)}}</router-link>
                    <span> • {{m.answerNum}} 人回答 </span>
                    </div>
                </td>
                <td>
                    <div class="mt-1">{{m.createdAt}}</div>
                </td>

                <td>
                    <h5>
                    <span v-if="m.adoptAnswerId" class="badge badge-success f-12">已解决</span>
                    <span v-else class="badge badge-danger f-12">待解决</span>
                    </h5>
                </td>
                <td>
                    <div class="mt-1 mb-1 f-16">
                    <a @click="onDelete(m.id,i)" href="javascript:;" class="ml-2">
                        <b-icon icon="trash"></b-icon>
                    </a>
                    </div>
                </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                <td colspan="4" class="text-center">
                    <div class="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
                    <div class="text-gray-777">暂无问题，如有疑问您还可以</div>
                    <div class="ml-3">
                        <router-link to="/ask/add" target="_blank">
                            <b-button size="sm" class="width-100" variant="success">提问</b-button>
                        </router-link>
                    </div>
                    
                    </div>
                </td>
                </tr>
            </tbody>
            </table>
        </div>
        <div v-if="list && list.total > list.size" class="mb-3 mt-3 mr-1">
            <div class="clearfix text-center">
            <b-pagination @change="onPageChange" class="float-right" :total-rows="list.total" :per-page="list.size" v-model="para.current"></b-pagination>
            </div>
        </div>
        </div>
        <!-- end card-body-->
    </div>
    </div>   
  </div>
</template>

<script>
export default {
  data() {
    return {
      para: {
        param: {
          status: true
        },
        size:10,
        current:1
      },
      list:[],
      statistics: null
    };
  },
  computed: {
   getTagValue() {
				return function(code,childcode) {
					let m = this.classList.find(x=> x.code == code);
					if(m) {
						let c = m.children.find(x=> x.code == childcode);
						if(c) {
							return c.name
						} else {
							return m.name
						}
					}
					return ''
				}
			}
  },
  methods: {
    getClass() {
      this.$http.post(this.$api.ask.getClass,{searchParam:''}).then(res => {
        this.classList = res.result;
      })
    },
    getList() {
      this.$http.post(this.$api.ask.getMyQuestion,this.para).then(res => {
				this.list = res.result;
			})
    },
    onDelete(id, i) {
      this.$dialog.alert({
					title: "温馨提示", 
					message: "是否删除该条问题?", 
					showCancelButton: true 
				}).then(() => {
           this.$http.delete(this.$api.ask.addQuestion + '/' + id).then(res => {
              if(res.code == 1) {
                this.list.records.splice(i, 1);
                if(this.list.records.length == 0) {
                  this.onPageChange(1);
                }
              }
          })
				})
    
    },
    onPageChange(i) {
      this.para.current = i;
      this.getList();
    },
    getStatistics() {
      this.$http.post(this.$api.ask.getQuestionStatistics).then(res => {
				this.statistics = res.result;
			})
    }
  },
  created() {
     this.getStatistics();
     this.getClass();
     this.getList();
  }
};
</script>

<style scoped>
@import url("~@/assets/css/ask.css");
</style>
