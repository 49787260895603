<template>
  <div>
    <b-alert show>
      <span
        v-if="statistics.answerNumber">回答数 {{ statistics.answerNumber }} , 回答被采纳 {{ statistics.adoptNumber }} , 回答采纳率 {{ statistics.adoptNumber > 0 && statistics.answerNumber > 0 ? parseFloat((statistics.adoptNumber / statistics.answerNumber * 100).toFixed(2)) : 0 }}%
      </span>
    </b-alert>
    <div class="w-100">
      <div class="card border-0">
        <div class="card-body p-0">
          <div class="table-responsive">
            <table class="table table-centered border mb-0">
              <thead class="table-light">
                <tr>
                  <th class="w-65">回答</th>
                  <th>回答时间</th>
                  <th>状态</th>
                  <th style="width: 80px">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(m,i) in list.records" :key="i">
                  <td>
                    <div v-html="'我的回答：' + m.content.replace(/<[^>]*>/g,'')"></div>
                    <div class="aw-common-list mt-2">
                      <div class="aw-item pb-0 pt-0">
                        <div class="aw-user-name">
                         <img v-if="m.askUserHeadPic != null && m.askUserHeadPic != ''" class="aw-user-img rounded-circle" :src="m.headPic">
												 <img v-else class="aw-user-img rounded-circle" src="@/assets/img/headpic.png">
                        </div>
                        <div class="aw-question-content">
                          <h4>
                            <router-link :to="'/ask/detail?id=' + m.questionId" target="_blank"
                              >{{m.askUserNicName}}:
                              {{m.questionTitle}}</router-link
                            >
                          </h4>
                          <p>
                            <span>
                              {{m.questionAdoptAnswerId ? '已解决':'未解决'}} • {{m.questionCreatedAt}}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="mt-1">{{m.createdAt}}</div>
                  </td>

                  <td>
                    <h5>
                      <span v-if="m.questionAdoptAnswerId == m.id" class="badge badge-success f-12"
                        >被采纳</span
                      >
                       <span v-else class="badge badge-secondary f-12"
                        >未采纳</span
                      >
                    </h5>
                  </td>
                  <td>
                    <div class="mt-1 mb-1 f-16">
                      <a @click="onDelete(m.id,i)" href="javascript:;" class="ml-2">
                        <b-icon icon="trash"></b-icon>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
           <div v-if="list && list.total > list.size" class="mb-3 mt-3 mr-1">
            <div class="clearfix text-center">
            <b-pagination @change="onPageChange" class="float-right" :total-rows="list.total" :per-page="list.size" v-model="para.current"></b-pagination>
            </div>
        </div>
        </div>
        <!-- end card-body-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      para: {
        param: {
          status: true,
        },
        size: 10,
        current: 1,
      },
      list: [],
      statistics: {
        adoptNumber:'',
        answerNumber:''
      },
    };
  },
  computed: {
    getTagValue() {
      return function (code, childcode) {
        let m = this.classList.find((x) => x.code == code);
        if (m) {
          let c = m.children.find((x) => x.code == childcode);
          if (c) {
            return c.name;
          } else {
            return m.name;
          }
        }
        return "";
      };
    },
  },
  methods: {
    getClass() {
      this.$http
        .post(this.$api.ask.getClass, { searchParam: "" })
        .then((res) => {
          this.classList = res.result;
        });
    },
    getList() {
      this.$http.post(this.$api.ask.getMyAnswer, this.para).then((res) => {
        debugger;
        this.list = res.result;
      });
    },
    onDelete(id, i) {
      this.$dialog.alert({
					title: "温馨提示", 
					message: "是否删除该条回答?", 
					showCancelButton: true 
				}).then(() => {
          this.$http.delete(this.$api.ask.addAnswer + "/" + id).then((res) => {
            if (res.code == 1) {
              this.list.records.splice(i, 1);
              if (this.list.records.length == 0) {
                this.onPageChange(1);
              }
            }
          });
				})
    },
    onPageChange(i) {
      this.para.current = i;
      this.getList();
    },
    getStatistics() {
      this.$http.post(this.$api.ask.getAnswerStatistics).then((res) => {
        debugger;
        this.statistics = res.result;
      });
    },
  },
  created() {
    this.getStatistics();
    this.getClass();
    this.getList();
  },
};
</script>

<style scoped>
@import url("~@/assets/css/ask.css");
</style>
